<template>
  <div>
    <Loading v-show="show" />

    <div class="breadcrumb-banner-area">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="breadcrumb-text">
              <h1 class="text-left">{{ Magazine }}</h1>
              <div class="breadcrumb-bar">
                <ul class="breadcrumb text-left">
                  <li>
                    <router-link href="#" aria-label="title" to="/">{{
                      home
                    }}</router-link>
                  </li>
                  <li>{{ MagazineIssue }}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="about-page-area section-padding"
      style="text-align: center; padding-top: 50px !important"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-12 col-md-12 col-12">
            <div class="about-text-container">
              <h3 id="IssueTitle"></h3>
              <p id="IssueSubTitle" style="text-align: center"></p>
              <a
                aria-label="title"
                id="downloadIssue10"
                class="downloadIssu"
                target="_blank"
                >{{ DownloadFile }}</a
              ><br /><br />
              <img
                loading="lazy"
                alt=""
                id="ImagesIssue"
                style="height: auto; width: auto"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div
      class="contact-form-area section-padding"
      style="padding-top: 4px !important"
    >
      <div class="container">
        <div class="row">
          <div class="col-lg-3 col-12">
            <!-- <h4 class="contact-title"></h4> -->
            <div class="contact-text">
              <p
                v-for="issue in IssueArtical"
                :key="issue.postID"
                style="background-color: rgb(52, 152, 219) !important"
              >
                <span class="c-icon">
                  <i class="fa fa-chevron-left"></i>
                </span>
                <span class="c-text">
                  <a
                    href="#"
                    aria-label="title"
                    @click="getIssueById(issue.postID)"
                    >{{ issue.postTitle }}</a
                  >
                </span>
              </p>
            </div>
          </div>
          <div class="col-lg-9 col-12">
            <div class="course-details-content">
              <div class="single-course-details">
                <div class="row">
                  <div class="col-md-12">
                    <div class="single-item-text">
                      <h5 id="postTitle1_"></h5>
                      <p id="postSubTitle1_"></p>
                      <!-- <p id="postDetial_"></p> -->
                      <a
                        id="downloadArtical"
                        style="text-align: center"
                        class="downloadIssu"
                        target="_blank"
                        >{{ DownloadFile }}</a
                      ><br /><br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Loading from "./Loading";

import axios from "axios";
import ArJson from "../../public/i18n/Ar.json";
import EnJson from "../../public/i18n/En.json";

export default {
  components: {
    Loading,
  },
  data() {
    return {
      direction: "right",
      IssueArtical: [],
      Issues: [],
      lang: "",
      ArTranslat: ArJson,
      EnTranslat: EnJson,
      translate: "",
      LastNews: "",
      BtnMore: "",
      home: "",
      NewsTitle: "",
      DownloadFile: "",
      ID: this.$route.params.id,
      IssueTitle: "",
      IssueSubTitle: "",
      Magazine: "",
      MagazineIssue: "",
      downloadArticalShow: false,
    };
  },
  mounted() {
    var self_ = this;
    // self.downloadArticalShow=false;
    if (localStorage.getItem("lang") == null) {
      axios({
        method: "get",
        url: "https://api2.yuniv.net:444/languages/getLangIsMain",
      }).then(function (response) {
        localStorage.setItem("lang", response.data[0]["LangId"]);
        self_.getApis();
      });
    } else self_.getApis();
  },
  methods: {
    //دالة لعرض بيانات البحث
    getIssueById(id) {
      var bodyFormDataartical = new FormData();
      var BrnID = this.$route.params.BrnId;
      bodyFormDataartical.append("check", "getPostById");
      bodyFormDataartical.append("Lang", localStorage.getItem("lang"));
      bodyFormDataartical.append("PostId", id);
      bodyFormDataartical.append("FbrnId", BrnID);
      bodyFormDataartical.append("Type", "Univ.Research.issue.artical");
      bodyFormDataartical.append("ParentId", this.$route.params.id);
      bodyFormDataartical.append("PostName", "");
      bodyFormDataartical.append("Pno", "1");
      axios({
        method: "post",
        url: "https://api2.yuniv.net:444/our_team/getPost",
        data: bodyFormDataartical,
      })
        .then(function (response) {
          document.getElementById("postTitle1_").innerText =
            response.data[0]["postTitle"];
          document.getElementById("postSubTitle1_").innerText =
            response.data[0]["postSubTitle"];
          if (response.data[0]["postFile"]) {
            document
              .getElementById("downloadArtical")
              .setAttribute(
                "href",
                "https://api2.yuniv.net:444/images/post/" +
                  response.data[0]["postFile"]
              );
            document.getElementById("downloadArtical").innerText = "  تحميل ";
          } else {
            document.getElementById("downloadArtical").removeAttribute("href");
            document.getElementById("downloadArtical").innerText = "";
          }
        })
        .catch(function () {
          //   console.log("error", response);
        });
    },
    //دالة لجلب جميع الابحاث الخاصة بالعدد
    getDataEvents(ID, Type) {
      window.scrollTo(0, 0);
      var self = this;
      var BrnID = this.$route.params.BrnId;

      if (localStorage.getItem("lang") == "Ar") {
        self.translate = self.ArTranslat;
        self.direction = "right";
      } else {
        self.translate = self.EnTranslat;
        self.direction = "left";
        let link1 = document.createElement("link");
        link1.setAttribute("rel", "stylesheet");
        link1.id = "langfilewebsites";
        link1.setAttribute("href", "./style-ltr.css");
        document.head.appendChild(link1);
      }
      self.LastNews = self.translate[0]["Home"]["LastNews"];
      self.BtnMore = self.translate[0]["Home"]["BtnDetials"];
      self.Detials = self.translate[0]["Home"]["Detials"];
      self.NewsTitle = self.translate[0]["Home"]["News"];
      self.home = self.translate[0]["HeaderAndFooter"]["Home"];
      self.DownloadFile = self.translate[0]["Home"]["DownloadFile"];
      self.Magazine = self.translate[0]["Home"]["MagazineIssue"];

      var bodyFormDataType = new FormData();
      bodyFormDataType.append("check", "getPost");
      bodyFormDataType.append("PostId", "");
      bodyFormDataType.append("Lang", localStorage.getItem("lang"));
      bodyFormDataType.append("FbrnId", BrnID);
      bodyFormDataType.append("Type", Type);
      bodyFormDataType.append("ParentId", ID);
      bodyFormDataType.append("PostName", "");
      bodyFormDataType.append("Pno", "-1");
      axios({
        method: "post",
        url: "https://api2.yuniv.net:444/our_team/getPost",
        data: bodyFormDataType,
      })
        .then(function (response) {
          self.IssueArtical = response.data;
          if (self.IssueArtical.length != 0) {
            self.getIssueById(response.data[0]["postID"]);
          }
        })

        .catch(function () {
          //   console.log("error", response);
        });
    },
    //لعرض العدد
    getApis() {
      var self = this;
      self.getDataEvents(this.$route.params.id, this.$route.params.type);

      if (localStorage.getItem("lang") == "Ar") {
        self.translate = self.ArTranslat;
        self.direction = "right";
      } else {
        self.translate = self.EnTranslat;
        self.direction = "left";
        let link1 = document.createElement("link");
        link1.setAttribute("rel", "stylesheet");
        link1.id = "langfilewebsites";
        link1.setAttribute("href", "./style-ltr.css");
        document.head.appendChild(link1);
      }
      self.LastNews = self.translate[0]["Home"]["LastNews"];
      self.BtnMore = self.translate[0]["Home"]["BtnDetials"];
      self.Detials = self.translate[0]["Home"]["Detials"];
      self.NewsTitle = self.translate[0]["Home"]["News"];
      self.home = self.translate[0]["HeaderAndFooter"]["Home"];
      self.DownloadFile = self.translate[0]["Home"]["DownloadFile"];
      self.Magazine = self.translate[0]["Home"]["MagazineIssue"];

      var bodyFormDataissue = new FormData();
      bodyFormDataissue.append("check", "getPostById");
      bodyFormDataissue.append("PostId", this.$route.params.id);
      bodyFormDataissue.append("Lang", localStorage.getItem("lang"));
      bodyFormDataissue.append("FbrnId", this.$route.params.BrnId);
      bodyFormDataissue.append("Type", "Univ.Research.issue");
      bodyFormDataissue.append("ParentId", "NTROOT0");
      bodyFormDataissue.append("PostName", "");
      bodyFormDataissue.append("Pno", "-1");
      axios({
        method: "post",
        url: "https://api2.yuniv.net:444/our_team/getPost",
        data: bodyFormDataissue,
      })
        .then(function (response) {
          //   var arr = response.data;
          //   if (arr.length == 0) self.showSlider = false;
          //   else self.showSlider = true;
          self.Issues = response.data;
          var issuTitleid = response.data;
          if (issuTitleid.length != 0) {
            document.getElementById("IssueTitle").innerText =
              response.data[0]["postTitle"];
            self.MagazineIssue = response.data[0]["postTitle"];
            document.getElementById("IssueSubTitle").innerText =
              response.data[0]["postSubTitle"];

            document
              .getElementById("ImagesIssue")
              .setAttribute(
                "src",
                "https://api2.yuniv.net:444/images/post/" +
                  response.data[0]["postImage"]
              );

            if (response.data[0]["postFile"]) {
              document
                .getElementById("downloadIssue10")
                .setAttribute(
                  "href",
                  "https://api2.yuniv.net:444/images/post/" +
                    response.data[0]["postFile"]
                );
              document.getElementById("downloadIssue10").innerText = "  تحميل ";
            } else document.getElementById("downloadIssue10").remove();
          }
        })

        .catch(function () {
          //   console.log("error", response);
        });
      setTimeout(() => {
        self.show = "false";
        document.querySelectorAll("div.loader").forEach(function (element) {
          element.setAttribute("hidden", true);
        });
      }, 100);
    },
  },
};
</script>

<style scoped>
.title {
  width: 100% !important;
  text-align: right !important ;
  background: #fff !important ;
  position: relative !important ;
}
.single-sidebar-widget .tags li a {
  background: #0c3ef7 none repeat scroll 100% 0;
  border: 1px solid #0c3ef7;
  color: #ffffff;
  float: right;
  margin-bottom: 13px;
  margin-left: 6px;
  padding: 11px 35px;
  position: absolute;
}
.img_left {
  height: 70px !important;
  widows: 70px im !important;
}
.recent-text p {
  font-size: 15px;
  line-height: 23px;
}
.single-latest-item img {
  /* width: 870px; */
  height: 435px;
  margin-top: 10px;
}
.news-details-content .single-latest-text {
  padding: 28px 20px 0px;
  text-align: justify;
}
#download {
  color: #3613ec;
  font-size: 20px;
}

.newsletter-area {
  background: #0c3ef7 none repeat scroll 100% 0;
  color: #ffffff;
  position: relative;
}

.newsletter-form {
  overflow: hidden;
  padding: 7px 0;
}

.newsletter-area:before {
  height: 65px;
  right: 0;
  left: auto;
  top: -13px;
  width: 27%;
}

.newsletter-area:after {
  border-width: 32.5px 27px;
  right: 27%;
  left: auto;
  top: -13px;
}

.newsletter-content {
  padding-top: 0px;
  z-index: 9;
  position: relative;
}

.ticker-left {
  display: inline-block;
  font-weight: bold;
  min-width: 50%;
  margin-top: 0px;
  margin-bottom: -1rem !important;
}

.single-event-image span {
  display: block;
  font-family: "montserratsemi_bold";
  font-size: 26px;
  text-align: center;
}
.imagesNews_ {
  width: 236px;
  height: 234px;
}

.c-text a {
  cursor: pointer;
}
.ImagesFID {
  width: 100% !important;
}
.about-area h3 {
  font-size: 30px;
  margin-bottom: 79px;
  padding-top: 0px;
  z-index: 9;
  position: relative;
}
.about-area:before {
  height: 79px;
  right: 0;
  left: auto;
  top: -32px;
  width: 49.2%;
}
.contact-text p {
  background: #0c3ef7;
  padding: 11px;
  color: #fff;
  border-radius: 10px;
}
.contact-text p span a {
  color: #fff;
}
.single-item-text {
  padding: 15px;
  height: auto;
}
.course-details-content {
  margin: 0;
}
.single-item-text h5 {
  font-weight: bold;
}
.single-item-text p {
  margin-top: 10px;
  text-align: justify;
  line-height: 30px;
}
.single-course-details {
  padding: 10px 0 !important;
  margin-bottom: 0;
  box-shadow: -1px 8px 15px 5px #ccc;
}
.about-container h3 {
  color: #000;
}
.header-logo-menu.stick.semi-transparent,
.gallery-img,
.gallery-img .hover-effect,
.single-teacher-image > a:after {
  background: rgb(52 152 219 / 63%) none repeat scroll 0 0;
}
#gelery {
  padding: 10px;
}
#groupImages {
  background: #0c3ef7;
  padding: 11px;
  color: #fff;
  border-radius: 30px;
}
.btn-warning {
  text-align: center;
  margin-top: 30px;
}

.downloadIssu {
  color: rgb(0, 140, 255);
}

.downloadIssu:hover {
  color: rgb(71, 73, 75);
}
</style>
